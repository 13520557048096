// Generated by ReScript, PLEASE EDIT WITH CARE


var empty = {};

export {
  empty ,
  
}
/* No side effect */
