// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.bs.js";
import * as Snabbdom from "./bindings/Snabbdom.bs.js";
import * as Snabbdom$1 from "snabbdom";

function make(store, state) {
  return Snabbdom$1.fragment([
              Snabbdom$1.h("div.absolute.absolute-middle.top-[70vh].flex-row.justify-center.text-center", {
                    style: {
                      top: "70vh"
                    }
                  }, [
                    Snabbdom$1.h("h1.text-2xl", Snabbdom.empty, ["Breathe!"]),
                    Snabbdom$1.h("h1.text-white/50.mb-4", Snabbdom.empty, ["by Tamim Arafat"]),
                    Snabbdom$1.h("a.self-center", {
                          props: {
                            href: "https://github.com/arafatamim/breathe",
                            title: "Git Repository",
                            target: "_blank"
                          }
                        }, [Icons.Git.make("m-auto", 30, undefined)])
                  ]),
              Snabbdom$1.h("div.flex.flex-col.gap-6.justify-center", Snabbdom.empty, [Snabbdom$1.h("button.rounded-full.w-[50px].h-[50px]", {
                          style: {
                            width: "50px",
                            height: "50px"
                          },
                          on: {
                            click: (function (param) {
                                return Curry._1(store.dispatch, /* Start */0);
                              })
                          }
                        }, [Icons.Play.make("inline-block text-pink-500 ml-2px", 38, undefined)])])
            ]);
}

export {
  make ,
  
}
/* Icons Not a pure module */
