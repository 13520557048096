// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.bs.js";
import * as Snabbdom from "./bindings/Snabbdom.bs.js";
import * as Snabbdom$1 from "snabbdom";

function formatSeconds(num) {
  var min = num % 3600 / 60 | 0;
  var secs = num % 60;
  return String(min) + ":" + String(secs).padStart(2, "0");
}

function make(store, state) {
  return Snabbdom$1.fragment([
              Snabbdom$1.h("div.flex.flex-col.gap-6.justify-center", Snabbdom.empty, [
                    Snabbdom$1.h("div#outer-circle.rounded-full.flex.place-items-center.place-content-center", Snabbdom.empty, [Snabbdom$1.h("div#inner-circle.rounded-full.bg-white", Snabbdom.empty, [])]),
                    Snabbdom$1.h("button.absolute.left-2/4.top-2/4.-translate-x-2/4.-translate-y-2/4.rounded-full.w-[50px].h-[50px]", {
                          style: {
                            width: "50px",
                            height: "50px"
                          },
                          attrs: {
                            title: "Stop Exercise"
                          },
                          on: {
                            click: (function (param) {
                                return Curry._1(store.dispatch, /* Stop */1);
                              })
                          }
                        }, [Icons.Stop.make("inline-block text-pink-500", 28, undefined)])
                  ]),
              Snabbdom$1.h("h1.absolute.absolute-middle.text-2xl.top-[87vh]", {
                    style: {
                      top: "87vh"
                    }
                  }, [formatSeconds(state.timer)])
            ]);
}

export {
  formatSeconds ,
  make ,
  
}
/* Icons Not a pure module */
