// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rxjs from "rxjs";
import * as Curry from "rescript/lib/es6/curry.js";

function none(param) {
  
}

function fromAction(action, dispatch) {
  return Curry._1(dispatch, action);
}

function ofSub(sub) {
  return sub;
}

function fromPromise(promise) {
  return function (dispatch) {
    promise.then(Curry.__1(dispatch));
    
  };
}

var Effect = {
  none: none,
  fromAction: fromAction,
  ofSub: ofSub,
  fromPromise: fromPromise
};

function make(reducer, initialState) {
  var state = new Rxjs.BehaviorSubject(initialState);
  var dispatch = function (action) {
    var oldState = state.getValue();
    var match = Curry._2(reducer, oldState, action);
    state.next(match[0]);
    return Curry._1(match[1], dispatch);
  };
  var subscribe = function (callback) {
    state.subscribe(callback);
    
  };
  return {
          subscribe: subscribe,
          dispatch: dispatch
        };
}

export {
  Effect ,
  make ,
  
}
/* rxjs Not a pure module */
