// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Home from "./Home.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Store from "./Store.bs.js";
import * as Effect from "./Effect.bs.js";
import * as Exercise from "./Exercise.bs.js";
import * as Snabbdom from "./bindings/Snabbdom.bs.js";
import * as Snabbdom$1 from "snabbdom";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var patch = Snabbdom$1.init([
      Snabbdom$1.styleModule,
      Snabbdom$1.propsModule,
      Snabbdom$1.attributesModule,
      Snabbdom$1.eventListenersModule
    ], undefined, {
      experimental: {
        fragments: true
      }
    });

var mount = {
  contents: Js_option.getExn(Caml_option.nullable_to_opt(document.getElementById("app")))
};

var timeoutId = {
  contents: undefined
};

function decrementTimer(initialTimer) {
  var sub = function (dispatch) {
    timeoutId.contents = Caml_option.some(setTimeout((function (param) {
                return Curry._1(dispatch, /* SetTimer */{
                            _0: initialTimer - 1 | 0
                          });
              }), 1000));
    
  };
  if (initialTimer === 0) {
    return function (param) {
      return Effect.fromAction(/* Stop */1, param);
    };
  } else {
    return Effect.ofSub(sub);
  }
}

var initialState = {
  page: /* Home */0,
  timer: 110
};

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === 0) {
      return [
              {
                page: /* Exercise */1,
                timer: 110
              },
              decrementTimer(state.timer)
            ];
    }
    var id = timeoutId.contents;
    if (id !== undefined) {
      clearTimeout(Caml_option.valFromOption(id));
    }
    return [
            {
              page: /* Home */0,
              timer: 110
            },
            Effect.none
          ];
  }
  var num = action._0;
  return [
          {
            page: state.page,
            timer: num
          },
          decrementTimer(num)
        ];
}

var store = Store.make(reducer, initialState);

function app(state) {
  var match = state.page;
  return Snabbdom$1.h("div#root", Snabbdom.empty, [Snabbdom$1.h("div.App", Snabbdom.empty, [match ? Exercise.make(store, state) : Home.make(store, state)])]);
}

Curry._1(store.subscribe, (function (state) {
        var component = app(state);
        mount.contents = Curry._2(patch, mount.contents, component);
        
      }));

export {
  patch ,
  mount ,
  timeoutId ,
  decrementTimer ,
  initialState ,
  reducer ,
  store ,
  app ,
  
}
/* patch Not a pure module */
