// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Snabbdom from "snabbdom";

function make(classNameOpt, sizeOpt, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  return Snabbdom.h("svg", {
              class: className,
              attrs: {
                class: className,
                width: size,
                height: size,
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            }, [Snabbdom.h("path", {
                    attrs: {
                      d: "M15 12.3301L9 16.6603L9 8L15 12.3301Z",
                      fill: "currentColor"
                    }
                  }, [])]);
}

var Play = {
  make: make
};

function make$1(classNameOpt, sizeOpt, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  return Snabbdom.h("svg", {
              attrs: {
                class: className,
                width: size,
                height: size,
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            }, [Snabbdom.h("path", {
                    attrs: {
                      d: "M7 7H17V17H7V7Z",
                      fill: "currentColor"
                    }
                  }, [])]);
}

var Stop = {
  make: make$1
};

function make$2(classNameOpt, sizeOpt, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  return Snabbdom.h("svg", {
              attrs: {
                class: className,
                width: size,
                height: size,
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            }, [Snabbdom.h("path", {
                    attrs: {
                      d: "M7 5C7 3.89543 7.89543 3 9 3C10.1046 3 11 3.89543 11 5C11 5.74028 10.5978 6.38663 10 6.73244V14.0396H11.7915C12.8961 14.0396 13.7915 13.1441 13.7915 12.0396V10.7838C13.1823 10.4411 12.7708 9.78837 12.7708 9.03955C12.7708 7.93498 13.6662 7.03955 14.7708 7.03955C15.8753 7.03955 16.7708 7.93498 16.7708 9.03955C16.7708 9.77123 16.3778 10.4111 15.7915 10.7598V12.0396C15.7915 14.2487 14.0006 16.0396 11.7915 16.0396H10V17.2676C10.5978 17.6134 11 18.2597 11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 18.2597 7.4022 17.6134 8 17.2676V6.73244C7.4022 6.38663 7 5.74028 7 5Z",
                      fill: "currentColor"
                    }
                  }, [])]);
}

var Git = {
  make: make$2
};

export {
  Play ,
  Stop ,
  Git ,
  
}
/* snabbdom Not a pure module */
